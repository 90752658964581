import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import colors from './lib/parts/colors';
import { ColorViewer, ColorSection, ColorWrapper } from './lib/viewers/color.js';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "colors"
    }}>{`Colors`}</h1>
    <p>{`Color scales`}</p>
    <Playground __position={0} __code={'<ColorWrapper>\n  <ColorSection>\n    {Object.keys(colors.uiColors.uiTexts).map(k => {\n      return (\n        <ColorViewer key={k} label={k} color={colors.uiColors.uiTexts[k]} />\n      )\n    })}\n  </ColorSection>\n  <ColorSection>\n    {Object.keys(colors.uiColors.uiDarks).map(k => {\n      return (\n        <ColorViewer key={k} label={k} color={colors.uiColors.uiDarks[k]} />\n      )\n    })}\n  </ColorSection>\n  <ColorSection>\n    {Object.keys(colors.uiColors.uiLights).map(k => {\n      return (\n        <ColorViewer key={k} label={k} color={colors.uiColors.uiLights[k]} />\n      )\n    })}\n  </ColorSection>\n  <ColorSection>\n    {Object.keys(colors.uiColors.uiAccents).map(k => {\n      return (\n        <ColorViewer key={k} label={k} color={colors.uiColors.uiAccents[k]} />\n      )\n    })}\n  </ColorSection>\n  <ColorSection>\n    {Object.keys(colors.uiColors.uiErrors).map(k => {\n      return (\n        <ColorViewer key={k} label={k} color={colors.uiColors.uiErrors[k]} />\n      )\n    })}\n  </ColorSection>\n</ColorWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      colors,
      ColorViewer,
      ColorSection,
      ColorWrapper,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
    <ColorWrapper mdxType="ColorWrapper">
        <ColorSection mdxType="ColorSection">
            {Object.keys(colors.uiColors.uiTexts).map(k => {
            return <ColorViewer key={k} label={k} color={colors.uiColors.uiTexts[k]} mdxType="ColorViewer" />;
          })}
        </ColorSection>
        <ColorSection mdxType="ColorSection">
            {Object.keys(colors.uiColors.uiDarks).map(k => {
            return <ColorViewer key={k} label={k} color={colors.uiColors.uiDarks[k]} mdxType="ColorViewer" />;
          })}
        </ColorSection>
        <ColorSection mdxType="ColorSection">
            {Object.keys(colors.uiColors.uiLights).map(k => {
            return <ColorViewer key={k} label={k} color={colors.uiColors.uiLights[k]} mdxType="ColorViewer" />;
          })}
        </ColorSection>
        <ColorSection mdxType="ColorSection">
            {Object.keys(colors.uiColors.uiAccents).map(k => {
            return <ColorViewer key={k} label={k} color={colors.uiColors.uiAccents[k]} mdxType="ColorViewer" />;
          })}
        </ColorSection>
        <ColorSection mdxType="ColorSection">
            {Object.keys(colors.uiColors.uiErrors).map(k => {
            return <ColorViewer key={k} label={k} color={colors.uiColors.uiErrors[k]} mdxType="ColorViewer" />;
          })}
        </ColorSection>
    </ColorWrapper>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      